<template>
  <div class="page-listings">
    <listings-tour v-if="$store.state.tour & !loading" />
    <transition name="menu-popover" v-if="!loading">
      <div v-if="pdfPreview" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <embed
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.pdfPreview"
          style="background: #fff"
        />
      </div>
    </transition>
    <transition name="menu-popover" v-if="!loading">
      <div v-if="allUnprintedInvs.length" class="lightbox">
        <h2 class="closeX" @click="closeALLLightbox()">
          <a>&#215;</a>
        </h2>
        <embed
          id="pdfID"
          type="text/html"
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          style="background: #fff; display: block; position: relative"
          :src="'data:application/pdf;base64,' + allUnprintedInvs"
        />
      </div>
    </transition>
    <section
      v-if="loading == true"
      data-v-5a90ec03
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 221px"
    >
      <div data-v-5a90ec03 class="container">
        <h1 class="text-white mb0 lh1">Loading Invoices....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <!--
        <h2 class="text-white lh1"></h2>
        <p class="text-white lh1">Loading details</p>
        -->
      </div>
    </section>

    <section v-else class="py-5 bg9 bs-shadow mobile-top">
      <div class="container">
        <h1 class="text-white mb0 lh1">Order Invoices</h1>
        <hr class="header-divide" />
        <div class="search-btns">
          <div class="left-side" style="margin-right: 20px">
            <div class="dates" id="dates">
              <h5 class="text-white mb0 lh1">
                Order invoices are available when an order has been shipped.
                Please review the invoice to confirm the order shipment.
              </h5>
            </div>
          </div>
        </div>
        <!--
        <input
          id="listings-step-1"
          class="top-search bs-large mt0"
          type="text"
          placeholder="Search listings by order number, PO number, or order name..."
          v-model="searchValue"
          @keyup.enter.prevent="searchQuery"
        />
        <div class="search-btns" style="display: flex; margin-bottom: 0">
          <div class="left-side" style="margin-right: 10px">
            <div id="listings-step-2" class="ac-select" style="margin-right: 0">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  Area Contractor
                </option>
                <option
                  v-for="ac in acs"
                  :key="ac.acid"
                  :value="ac.acid"
                  id="text"
                >
                  {{ " " + ac.acName + " (" + ac.acid + ")" }}
                </option>
   
              </select>
            </div>
            <div class="dates" id="dates" style="display: flex">
              <datepicker
                format="MMM dd yyyy"
                placeholder="From Date"
                v-model="startDate"
                wrapperClass="pickthedate"
                :typeable="true"
              ></datepicker>

              <datepicker
                id="listings-step-3"
                format="MMM dd yyyy"
                placeholder="To Date"
                v-model="endDate"
                wrapperClass="pickthedate"
                style="margin-right: 0"
                :typeable="true"
              ></datepicker>
            </div>
          </div>

          <div class="right-side" style="display: flex">
            <div
              class="search-btns"
              style="display: flex; margin-top: 0; margin-left: auto"
            >
              <button
                v-if="searchValue || startDate || endDate || acFilter"
                @click.prevent="searchQuery"
                class="search"
              >
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>
              <button v-else class="search" disabled>
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>

              <button
                v-if="searchValue || startDate || endDate || acFilter"
                @click="clearSearch"
                class="clear"
              >
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </button>
              <button v-else disabled class="clear">
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </button>
            </div>
          </div>
        </div>
        -->
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div>
            <div>
              <div
                v-if="loading == true"
                class="col-xs-12 col-sm-12 col-12 col-lg-12"
              >
                <div class="flex-box-wrap">
                  <div class="flex-internal">
                    <atom-spinner
                      :animation-duration="1000"
                      :size="40"
                      :color="'#575757'"
                    />
                    <p>Loading......</p>
                  </div>
                </div>
              </div>

              <div
                v-else-if="loading == false && !filteredListings.length"
                class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
              >
                <div class="card-back mb30 rounded bs-shadow p-3">
                  <div class="d-flex number-showing">
                    <button
                      @click="backLink"
                      class="mb0 lh1 mrauto list-btn dib d-flex center"
                    >
                      <i class="material-icons">keyboard_arrow_left</i>
                      Back
                    </button>
                  </div>
                  <h2
                    style="
                      margin: 30px 20px 30px 0;
                      color: #429da8;
                      font-weight: 400;
                      text-align: left;
                    "
                  >
                    It looks like there are no unprinted Invoices to show...
                  </h2>
                  <h3
                    style="margin: 30px 20px; color: #429da8; font-weight: 400"
                  ></h3>
                  <img src="@/assets/img/noData.svg" style="width: 100%" />
                </div>
              </div>
              <!-- ackowledgments only -->

              <div v-else class="page">
                <div
                  class="
                    listing-header
                    d-flex
                    align-items-center
                    p-3
                    mt10
                    mb10
                    rounded
                    b-shadow
                  "
                >
                  <div class="pag-wrapper">
                    <p class="mrauto">
                      <small>Page </small>{{ currPage }} <small>of</small>
                      {{ Math.ceil(this.filteredListings.length / 100) }}
                    </p>
                    <div id="listings-step-4" class="nav-btns mlauto">
                      <button v-if="this.currPage === 1" disabled>
                        <i class="material-icons">keyboard_arrow_left</i> Back
                      </button>

                      <button v-else @click.prevent="setPage(currPage - 1)">
                        <i class="material-icons">keyboard_arrow_left</i> Back
                      </button>

                      <button
                        v-if="
                          this.currPage === this.totalPage ||
                          this.currPage ==
                            Math.ceil(this.filteredListings.length / 100)
                        "
                        disabled
                      >
                        Next
                        <i class="material-icons">keyboard_arrow_right</i>
                      </button>

                      <button v-else @click.prevent="setPage(currPage + 1)">
                        Next
                        <i class="material-icons">keyboard_arrow_right</i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="listing">
                  <div v-if="$store.getters.isACKSnINVS" class="print5" style="display: flex">
                     <div
                      style="margin-left: auto"
                    >
                      <a
                        @click="NumberReset('Invoice', listings.length)"
                        style="cursor: pointer; color:#fff;"
                        class="clearBtns"
                        title="This will reset the Invoice notification number to zero"
                      >
                        <i class="fas fa-eraser" style="margin-right: 2px"></i>
                        Clear Invs
                      </a>
                      <a
                        @click="printFiveInvs"
                        style="cursor: pointer; margin-left: 5px; color:#fff;"
                        class="clearBtns"
                        title="This will print the five newest Invoices in this list"
                      >
                        <i class="fas fa-print" style="margin-right: 2px"></i>
                        Print 5 Invs
                      </a>
                    </div>

                  </div>
                  <div style="display: flex">
                    <div class="tab-heading">
                      <router-link
                        to="/listings"
                        class="order-tab tab-head inactive-tab"
                        ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                          Orders
                        </h5></router-link
                      >

                      <router-link
                        to="/acknowledgments"
                        class="ack-tab tab-head inactive-tab"
                        ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                          Acknowledgments
                        </h5></router-link
                      >

                      <router-link
                        to="/invoices"
                        class="inv-tab tab-head active-tab"
                        ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                          Invoices
                        </h5></router-link
                      >
                    </div>
                    <!--
                    <div
                      v-if="$store.getters.isACKSnINVS"
                      style="margin-left: auto"
                    >
                      <a @click="getAllInvs" class="printAll" title="This will print the five newest invoices in this list">
                        <i class="fas fa-print" style="margin-right: 2px"></i>
                        Print 5
                      </a>
                    </div>
                    -->
                    
                  </div>
                  <!--
                  <h2 class data-config-id="header">Unprinted Invoices</h2>
                  -->
                  <div class="table-responsive">
                    <table
                      class="table-striped table-bordered"
                      style="display: table"
                    >
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="table-link"
                            @click="dateCol('pdfdate')"
                            :class="
                              columnSort == 'pdfdate' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Date Posted </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'pdfdate'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link"
                            @click="sortCol('orderNo')"
                            :class="
                              columnSort == 'orderNo' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Order # </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'orderNo'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link"
                            style="width: 12%;"
                            @click="sortCol('customer_PO_No')"
                            :class="
                              columnSort == 'customer_PO_No'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">PO # </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'customer_PO_No'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link thirty"
                            @click="sortCol('projName')"
                            :class="
                              columnSort == 'projName' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Name </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'projName'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link ten"
                            @click="dateCol('entered_Date')"
                            :class="
                              columnSort == 'entered_Date'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Entered </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'entered_Date'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>



                          <th
                            scope="col"
                            class="table-link ten"
                            @click="dateCol('promised_Date')"
                            :class="
                              columnSort == 'promised_Date'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Promised </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'promised_Date'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th scope="col" style="width: 12%">
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Invoice </span>
                            </span>
                          </th>
                          <!--
                          <th scope="col" class="ten">
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Printed </span>
                            </span>
                          </th>
                          -->
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          scope="row"
                          v-bind:key="index"
                          v-for="(listing, index) in filteredListings.slice(
                            pageStart,
                            pageStart + countOfPage
                          )"
                        >
                          <td v-if="listing.pdfdate.length">
                            {{ moment(listing.pdfdate).format("MM-DD-YYYY") }}
                          </td>
                          <td v-if="listing.blobPath.length">
                            <router-link
                              @click="loadTop"
                              :id="index === 0 ? 'listings-step-5' : ''"
                              :to="/order-details/ + listing.orderNo"
                              >{{ listing.orderNo }}</router-link
                            >
                          </td>
                          <td >
                            {{ listing.customer_PO_No }}
                          </td>
                          <td >
                            {{ listing.projName }}
                          </td>
                          <td >
                            {{ moment(listing.entered_Date).format("MM-DD-YYYY")  }}
                          </td>

                          <td >
                            {{ moment(listing.promised_Date).format("MM-DD-YYYY")  }}
                          </td>

                          <td
                            v-if="listing.blobPath.length"
                            style="text-align: center"
                          >
                            <span
                              v-if="listing.blobPath.length"
                              @click="getPDF(listing.blobPath, index)"
                              class="material-icons document-link"
                              >description</span
                            >
                          </td>
                          <!--
                          <td v-if="listing.blobPath.length">
                            <input
                              type="checkbox"
                              aria-label="Checkbox for following text input"
                            />
                          </td>
                          -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- card -->
                <div
                  class="
                    listing-header
                    d-flex
                    align-items-center
                    p-3
                    mt10
                    mb-3
                    rounded
                    b-shadow
                  "
                >
                  <div class="pag-wrapper">
                    <p class="mrauto">
                      <small>Page</small> {{ currPage }} <small>of</small>
                      {{ Math.ceil(this.filteredListings.length / 100) }}
                    </p>
                    <div class="nav-btns mlauto">
                      <button v-if="this.currPage === 1" disabled>
                        <i class="material-icons">keyboard_arrow_left</i> Back
                      </button>

                      <button v-else @click.prevent="setPage(currPage - 1)">
                        <i class="material-icons">keyboard_arrow_left</i> Back
                      </button>

                      <button
                        v-if="
                          this.currPage === this.totalPage ||
                          this.currPage ==
                            Math.ceil(this.filteredListings.length / 100)
                        "
                        disabled
                      >
                        Next
                        <i class="material-icons">keyboard_arrow_right</i>
                      </button>

                      <button v-else @click.prevent="setPage(currPage + 1)">
                        Next
                        <i class="material-icons">keyboard_arrow_right</i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- page -->
            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import { HollowDotsSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import Datepicker from "vuejs-datepicker";
import ListingsTour from "../components/Tours/ListingsTour";
import NumberReset from "../shared/NumberReset.js";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + 'eyJhbGci7yJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIyREQyQzk4NC1BM0RELTQ5MjMtQUM1Mi01QjA0MzgyOTg5QUQiLCJVc2VyTmFtZSI6ImpvYXMiLCJFbWFpbCI6ImpvYXNAc3BhY2VzYXZlci5jb20iLCJGaXJzdE5hbWUiOiJKb3NodWEiLCJMYXN0TmFtZSI6Ik9hcyIsIkNvbXBhbnkiOiJTcGFjZXNhdmVyIENvcnBvcmF0aW9uIiwiRGVwYXJ0bWVudCI6IklTIiwiUGhvbmVOdW1iZXIiOiI5MjA1NjM2MzYyIiwiQ2VsbFBob25lIjoiIiwiRW1haWxEb21haW4iOiJzcGFjZXNhdmVyLmNvbSIsIkFDSWQiOlswXSwiUmVwSWQiOjAsIlJvbGVzIjpbInNzYyIsImRvY2xpYmFkbWluIiwiYWRtaW4iXSwiYWRtaW4iOnRydWUsInNzY2VtcGxveWVlIjp0cnVlLCJkb2NsaWJhZG1pbiI6dHJ1ZSwiTGFzdEFjdGl2aXR5IjoiMjAyMS0wNC0yMlQxMzo1MzoxNy4yMTNaIiwiaWF0IjoxNjE5MDk5NjM5LCJleHAiOjE2MjE2OTE2Mzl9.gWs9nahp7NAzljgV0jhS27setYqj-fo87lmaBkzj_D0'
  },
};

export default {
  name: "Invoices",
  components: {
    AtomSpinner,
    Avatar,
    Datepicker,
    ListingsTour,
  },
  data() {
    return {
      attributes: [
        {
          key: "today",
          highlight: true,
          dot: "red",
          dates: {
            start: null, // From the beginning of time
            end: new Date(), // Until today
          },
        },
      ],
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      backend: process.env.VUE_APP_SERVER_URL,
      searchValue: this.$route.query.search ? this.$route.query.search : "",
      search: "",
      storedListings: [],
      searchRender: false,
      startDate: this.$route.query.start ? this.$route.query.start : null,
      endDate: this.$route.query.end ? this.$route.query.end : null,
      searchStarted: false,
      acs: [],
      listings: [],
      acFilter: this.$route.query.ac ? this.$route.query.ac : null,
      allUnprintedInvs: "",
      selectedCat: "Show All",
      searching: false,
      loading: true,
      countOfPage: 100,
      currPage: 1,
      noListingFound: false,
      colSort: false,
      columnSort: "",
      pdfPreview: "",
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
      view: "orders",
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchStarted = false;
      console.log(
        `${this.ordermanageapi}/unprinted?ac=${this.$store.state.user.ACId[0]}&type=inv`
      );
      //this.acFilter = null;
      //this.searchValue = "";
      //this.startDate = null;
      //this.endDate = null;

      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {

      this.acListSync();

      if (
        this.searchValue.length ||
        this.acFilter != null ||
        this.startDate != null ||
        this.endDate != null
      ) {
        this.searchQuery();
      } else {
        this.listingSync();
      }


    },

    listingSync: function () {
      this.loading = true;
      return axios
        .get(
          `${this.ordermanageapi}/unprinted?ac=${this.$store.state.user.ACId[0]}&type=inv`,
          auth
        )
        .then((response) => {
          this.listings = response.data;
          this.loading = false;
        });
    },

    getAllInvs: function () {
      return axios
        .get(`${this.ordermanageapi}/multireader?type=inv`, auth)
        .then((response) => {
          console.log(response);
          const data = response.data;
          this.allUnprintedInvs = data;
        });
    },

    printFiveInvs: function () {
        this.$swal({
          html:
            `<h5 style="margin-bottom: 0">Print Five Invoices</h5>` +
            '<br/>' +
            '<div style="overflow:scroll; max-height: 300px; text-align: left;"><small>' +
            `<p>Accepting will allow you to print a group of five Invoice notifications at a time. After printing, the five items will be removed from the list on this page.</p>` +
            '</small></div>',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: `Print`,
          denyButtonText: `Cancel`,
          customClass: {
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
          }
        }).then((result) => {
          if (result.isConfirmed) {
            return axios.get(`${this.ordermanageapi}/multireader?type=inv`, auth).then((response) => {
            console.log(response);
            const data = response.data;
            this.allUnprintedInvs = data;
            this.$noty.success("Invoice notifications are now cleared");
            return '';
          });                  
          } else {
            //this.$noty.error("Media usage agreement must be accepted for full functionality.");
            return '';
          }
        });
    },

    acListSync: function () {
      this.loading = true;

      if (!this.$store.state.acList.acList.length) {
        //console.log("getting acList");
        return axios
          .get(`${this.ordermanageapi}/aclist?active=true`, auth)
          .then((response) => {
            //console.log(response);
            const data = response.data;
            /*

            const low = data.filter(function (e) {
              return e.acid < 210;
            });

            const mid = data.filter(function (e) {
              return e.acid == 80069;
            });

            const high = data.filter(function (e) {
              return e.acid > 800000 && e.acid < 800250;
            });

            const all = low.concat(high).concat(mid);
            const alph = all.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            */
            const alph = data.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            this.acs = alph;
            //this.acs.sort((a, b) => (a.acName > b.acName) ? 1 : -1);
            //this.loading = false;
            this.$store.commit("UPDATE_ACLIST", this.acs);
          });
      } else {
        //console.log("vuex has length");
        this.acs = this.$store.state.acList.acList;
        this.loading = false;
      }
    },
    toUpperCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    searchReset: function () {
      this.selectedCat = "Show All";
      this.acFilter = 0;
    },
    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },
    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },
    searchQuery(event) {
      this.searchStarted = true;
      if (this.searchStarted === true) {
        this.storedListings = this.listings;
      }

      this.loading = true;
      this.searching = true;
      this.search = "";

      if (
        this.searchValue.length &&
        this.acFilter != null &&
        this.startDate != null &&
        this.endDate != null
      ) {
        //all four
        history.pushState(
          {},
          "",
          `/listings?search=${this.searchValue}&ac=${this.acFilter}&start=${this.startDate}&end=${this.endDate}`
        );
      } else if (
        this.searchValue.length &&
        this.acFilter != null &&
        this.startDate != null
      ) {
        //search, ac, and start
        history.pushState(
          {},
          "",
          `/listings?search=${this.searchValue}&ac=${this.acFilter}&start=${this.startDate}`
        );
      } else if (
        this.searchValue.length &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        //search, ac, and end
        history.pushState(
          {},
          "",
          `/listings?search=${this.searchValue}&ac=${this.acFilter}&end=${this.endDate}`
        );
      } else if (this.searchValue.length && this.acFilter != null) {
        //search and AC
        history.pushState(
          {},
          "",
          `/listings?search=${this.searchValue}&ac=${this.acFilter}`
        );
      } else if (this.searchValue.length && this.endDate != null) {
        //search, and end
        history.pushState(
          {},
          "",
          `/listings?search=${this.searchValue}&end=${this.endDate}`
        );
      } else if (this.searchValue.length && this.startDate != null) {
        //search and start
        history.pushState(
          {},
          "",
          `/listings?search=${this.searchValue}&start=${this.startDate}`
        );
      } else if (this.acFilter != null && this.endDate != null) {
        //ac and end
        history.pushState(
          {},
          "",
          `/listings?ac=${this.acFilter}&end=${this.endDate}`
        );
      } else if (this.acFilter != null && this.startDate != null) {
        //ac, and start
        history.pushState(
          {},
          "",
          `/listings?ac=${this.acFilter}&start=${this.startDate}`
        );
      } else if (this.searchValue.length) {
        history.pushState({}, "", `/listings?search=${this.searchValue}`);
      } else if (this.acFilter != null) {
        history.pushState({}, "", `/listings?ac=${this.acFilter}`);
      } else if (this.startDate != null) {
        history.pushState({}, "", `/listings?start=${this.startDate}`);
      } else if (this.endDate != null) {
        history.pushState({}, "", `/listings?end=${this.endDate}`);
      } else {
        history.pushState({}, "", `/listings`);
      }

      return axios.get(this.searchEndpointCompute, auth).then((response) => {
        this.listings = response.data;

        //console.log(response);
        this.loading = false;
        this.searching = false;
      });
    },
    clearSearch() {
      this.searchValue = "";
      this.searchRender = !this.searchRender;
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;

      if (this.searchStarted == true) {
        //this.listings = this.storedListings;
        this.listingSync();
        this.searchStarted = false;
      }

      history.pushState({}, "", `/listings`);

      //this.listings = this.storedListings;

      this.$forceUpdate();

      //this.$forceUpdate();
      //Vue.set(this.listings, this.storedListings);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    sortCol: function (type) {
      console.log(type);
      this.columnSort = type;
      const COLUMN = type;
      //console.log(COLUMN)
      /*
        const distantFuture = new Date(8640000000000000);
        const firstSort = type;

        let sorted = filteredListings.sort((a, b) => {
          let dateA = a[firstSort] ? new Date(a[firstSort]) : distantFuture;
          let dateB = b[firstSort] ? new Date(b[firstSort]) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        });

        this.filteredListings = sorted;
        */

      if (this.colSort) {
        this.filteredListings.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      } else {
        this.filteredListings.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      }
      this.colSort = !this.colSort;
      this.columnSort = "";
    },
    dateCol: function (type) {
      console.log(type);
      this.columnSort = type;
      const COLUMN = type;
      const distantFuture = new Date(8640000000000000);

      if (this.colSort) {
        this.filteredListings.sort((a, b) => {
          let dateA = a[COLUMN] ? new Date(a[COLUMN]) : distantFuture;
          let dateB = b[COLUMN] ? new Date(b[COLUMN]) : distantFuture;
          return dateA > dateB ? 1 : -1;
        });
      } else {
        this.filteredListings.sort((a, b) => {
          let dateA = a[COLUMN] ? new Date(a[COLUMN]) : distantFuture;
          let dateB = b[COLUMN] ? new Date(b[COLUMN]) : distantFuture;
          return dateA < dateB ? 1 : -1;
        });
      }
      this.colSort = !this.colSort;
      this.columnSort = "";
    },
    loadTop() {
      window.scrollTo(0, 0);
    },
    calcProcessing: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "In Engineering" ||
          orderstate == "In Planning" ||
          orderstate == "Planned" ||
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          //console.log("true");
          // this.processing = true;
          return true;
        } else {
          // this.processing = false;
          //console.log("false");
          return false;
        }
      } else {
        return;
      }
    },
    calcPreProd: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate === "In Planning" ||
          orderstate == "Planned" ||
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    calcInProd: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    calcShipped: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate === "Partially Delivered" ||
          orderstate === "Delivered" ||
          orderstate === "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    getPDF(link, index) {
      return axios
        .get(`${this.ordermanageapi}/${link}`, auth)
        .then((response) => {
          console.log(response.data);
          this.pdfPreview = response.data[0].pdfblog;
          this.filteredListings.splice(index, 1);
        });
    },
    showPDF(doc) {
      this.pdfPreview = doc;
    },
    closeLightbox() {
      this.pdfPreview = "";
    },
    backLink: function () {
      this.$router.back();
    },
    closeALLLightbox() {
      this.listingSync();
      this.allUnprintedInvs = "";
      //this.listings = "";
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredListings: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.listings.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.OrderNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.listings.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.listings;
      }
    },

    foundNoListing: function () {
      if (this.search.length > 1 && this.filteredListings.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.listings.length / this.countOfPage);
    },
    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.listings
      ) {
        //console.log("fired off");
        this.listings = this.storedListings;
      }
      return;
    },
    searchEndpointCompute: function () {
      //has order number, no start, no end, no ac
      if (
        this.searchValue.length &&
        !this.startDate &&
        !this.endDate &&
        !this.acFilter
      ) {
        return `${this.ordermanageapi}/order?search=${this.searchValue}`;
      } else if (
        //has order number, and start, no end, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.endDate === null &&
        this.acFilter === null
      ) {
        return `${this.ordermanageapi}/order?search=${
          this.searchValue
        }&start=${this.formatDate(this.startDate)}`;
      } else if (
        //has order number, and end, no start, no ac
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?search=${
          this.searchValue
        }&end=${this.formatDate(this.endDate)}`;
      } else if (
        // has three, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?search=${
          this.searchValue
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //only has end
        !this.searchValue &&
        this.startDate === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //only has start
        !this.searchValue &&
        this.startDate != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/order?start=${this.formatDate(
          this.startDate
        )}`;
      } else if (
        //has start and end
        !this.searchValue &&
        this.startDate != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?start=${this.formatDate(
          this.startDate
        )}&end=${this.formatDate(this.endDate)}`;
      } else if (
        //has all 4
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?ac=${this.acFilter}`;
      } else if (
        //has only ac
        !this.searchValue &&
        this.startDate === null &&
        this.endDate === null &&
        this.acFilter != null
      ) {
        return `${this.ordermanageapi}/order?ac=${this.acFilter}`;
      } else if (
        //has ac and start
        !this.searchValue &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/order?start=${this.formatDate(
          this.startDate
        )}&ac=${this.acFilter}`;
      } else if (
        //has ac and end
        !this.searchValue &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?end=${this.formatDate(
          this.endDate
        )}&ac=${this.acFilter}`;
      } else if (
        //has ac and number
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/order?search=${this.searchValue}&ac=${this.acFilter}`;
      } else if (
        //has ac and start and end
        !searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/order?ac=${
          this.acFilter
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}`;
      } else {
        return `${this.ordermanageapi}/order`;
      }
    },
  },
  created() {
    this.$store.commit("showNavigation");
    this.NumberReset = NumberReset.numberReset;
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.listing-bottom {
  align-items: flex-end;
}
.listing-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.date-area {
  margin-top: 15px;
}
.pag-wrapper {
  display: flex;
  width: 100%;
}
.pag-wrapper p {
  margin-bottom: 0;
}
.mrauto {
  margin-right: auto;
}
.search-btns {
  /*display: flex;*/
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 23px 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.search-btns .clear {
  margin-right: 0;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.right-side {
  text-align: right;
  height: 57px;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
@media all and (min-width: 992px) {
  table {
    min-width: 100%;
  }
}

@media all and (max-width: 991px) {
  table {
    width: 800px !important;
    overflow: scroll;
  }
}
/*
flex-direction: column;
*/
.document-link {
  color: #429da8;
  cursor: pointer;
}
.document-link:hover {
  color: #00b4b4;
  cursor: pointer;
}
.document-link:active {
  color: #00b4b4;
  cursor: pointer;
}
.post {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
}
.printAll {
  color: #4d5967;
  transition: color 0.5s ease;
  cursor: pointer;
}
.printAll:hover,
.printAll:active {
  color: #00b4b4;
}
.clearBtns{
      background: #00b4b4;
      background: #429da8;
      padding: 6px 10px;
      border-radius: 5px;
      color:#fff;
}
</style>
